import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'app';
  constructor(private swUpdate: SwUpdate) {}
  ngOnInit() {
    
    console.log("sw update is enabled", this.swUpdate.isEnabled);
    if (this.swUpdate.isEnabled) {
        this.swUpdate.available.subscribe(() => {
            if(confirm("Det finns en ny version, ladda in den?")) {
                window.location.reload();
            }
        });
    }    
  }
}
