import { Component, Inject } from '@angular/core';

@Component({
  templateUrl: './group-start.component.html'
})
export class GroupStartComponent {

  constructor() {

  }
}