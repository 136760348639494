import { Component } from '@angular/core';

@Component({
  selector: 'app-adminstart',
  templateUrl: './adminstart.component.html',
  styleUrls: ['./adminstart.component.scss']
})
export class AdminstartComponent {
  public show: string;

}
