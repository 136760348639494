import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { GroupService } from './group.service';
import { IGroup, IMember } from './group.model';
import { MemberCostPipe } from '../components/member-cost-pipe/member-cost.pipe';

@Component({
  templateUrl: './group.component.html'
})
export class GroupComponent implements OnInit {

  constructor(http: HttpClient, @Inject('BASE_URL') baseUrl: string,
  private route:ActivatedRoute,
  private groupService: GroupService) { }

  group: IGroup = <IGroup> {}; 
  totalCost: number = 0;

  ngOnInit() {
    this.groupService.getGroup(+this.route.snapshot.paramMap.get('id')).subscribe(value => {
      this.group = value;

      value.costs.forEach(cost => {
        this.totalCost += cost.amount;
      });

      console.dir(this.group);
    });
  }

  costByMember(member: IMember) : number {
    let value: number = 0;
    this.group.costs.forEach(cost => {
      //console.log("member", member);
      //console.log("cost", cost.memberHoldCost.username);
      if (cost.memberHoldCost.username === member.username) {
        value += cost.amount;
      }
    });
    return value;
  }
}