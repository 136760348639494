import { Component, OnInit, Inject } from '@angular/core';
import { GroupService } from './group.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TOASTR_TOKEN, Toastr } from '../services/toastr.service';
import { AuthorizeService, IUser } from 'src/api-authorization/authorize.service';
import { IGroup, IBalancingStartValues, IBalancing, IBalancingTransaction } from './group.model';

@Component({
  templateUrl: './group-balancing.component.html'
})
export class GroupBalancingComponent implements OnInit {
    
  constructor(private route:ActivatedRoute, private authService: AuthorizeService, private groupService: GroupService, private router: Router, @Inject(TOASTR_TOKEN) private toastr: Toastr) {
  }

  groupId: number;
  group: IGroup = <IGroup> {}; 
  startValues: IBalancingStartValues = <IBalancingStartValues> {};
  balanceValue: IBalancing = <IBalancing> {};
  currentUsername: string;

  userPayments: IBalancingTransaction[] = [];
  userReceive: IBalancingTransaction[] = [];

  ngOnInit() {
    this.authService.getUser().subscribe(user => this.currentUsername = user.name );

    this.groupId = +this.route.snapshot.paramMap.get('id');
    this.groupService.getGroup(this.groupId).subscribe(value => {
      this.group = value;
    });

    this.groupService.getBalancingStartValues(this.groupId).subscribe(value => {
      this.startValues = value;
    })

    this.loadTransactions();
  }

  loadTransactions() {
    while(this.userPayments.length){
      this.userPayments.pop();
    }    

    while(this.userReceive.length){
      this.userReceive.pop();
    }    

    this.groupService.balanceGroup(this.groupId).subscribe(value => {
      this.balanceValue = value;
      if (value.transactions.length > 0) {
        value.transactions.forEach(balance => {
          if (balance.userFromUsername === this.currentUsername) {
            this.userPayments.push(balance)
          }
          else if (balance.userToUsername === this.currentUsername) {
            this.userReceive.push(balance)
          }
        });
      }
    })
  }

  registerPayment(payment: IBalancingTransaction) {
    this.groupService.addPayment(payment, this.groupId).subscribe((res) => {
      this.loadTransactions();
    })
  }

  cancel() {
    this.router.navigate(['group', this.groupId]);
  }

}


