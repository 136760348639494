import { BrowserModule } from '@angular/platform-browser';
import { NgModule, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { FormsModule, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { ApiAuthorizationModule } from 'src/api-authorization/api-authorization.module';
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard';
import { AuthorizeInterceptor } from 'src/api-authorization/authorize.interceptor';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

import { NgHttpLoaderModule } from 'ng-http-loader';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/se';
registerLocaleData(localeFr, 'se');


import { GroupStartComponent, 
  GroupCreateComponent, 
  GroupService, 
  GroupComponent,
  GroupInviteComponent,
  GroupHasInviteComponent,
  GroupInvitedComponent,
  GroupBalancingComponent, GroupAdminComponent
} from 'src/app/group/index';

import { appRoutes } from './routes';
import { CollapsibleComponent, CostDateSorterPipe, GroupListComponent, MemberCostPipe, MyProfileComponent, MyProfileService } from './components';
import { TOASTR_TOKEN, Toastr, JQ_TOKEN } from './services/index';
import { CostService, CostAddComponent, CostTimelineComponent, CostViewEditComponent, CostFormComponent } from './cost';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AdminstartComponent } from './admin/adminstart/adminstart.component';
import { LogViewerComponent } from './admin/log-viewer/log-viewer.component';
import { SplitterUsersComponent } from './admin/splitter-users/splitter-users.component';

let toastr:Toastr = window['toastr'];
let jQuery = window['$'];


window['toastr'].options = {
  "closeButton": false,
  "debug": false,
  "newestOnTop": false,
  "progressBar": true,
  "positionClass": "toast-top-center",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "1500",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HomeComponent,
    GroupListComponent,
    GroupStartComponent,
    GroupCreateComponent,
    GroupComponent,
    CostAddComponent,
    CostTimelineComponent,
    CostViewEditComponent,
    CostFormComponent,
    GroupInviteComponent,
    GroupHasInviteComponent,
    GroupBalancingComponent,
    GroupAdminComponent,
    GroupInvitedComponent,
    MemberCostPipe,
    CostDateSorterPipe,
    MyProfileComponent,
    CollapsibleComponent,
    AdminstartComponent,
    LogViewerComponent,
    SplitterUsersComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'slitterApp' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ApiAuthorizationModule,
    NgHttpLoaderModule.forRoot(),
    RouterModule.forRoot(appRoutes, { useHash: false }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    
  ],
  providers: [
    GroupService,
    CostService,
    MyProfileService,
    { provide: TOASTR_TOKEN, useValue: toastr },
    { provide: JQ_TOKEN, useValue: jQuery },
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
