import { Pipe, PipeTransform } from '@angular/core';
import { ICost } from 'src/app/cost';
/*
Sort Icosts after create date
 * Example:
*/
@Pipe({name: 'costDateSorter'})
export class CostDateSorterPipe implements PipeTransform {
  transform(costs: ICost[], ascending: boolean): ICost[] {

    if (!costs) {
      return costs;
    }
    if (ascending) {
      return costs.sort((a,b) => (b.costDate > a.costDate) ? 1 : (a.costDate > b.costDate) ? -1 : 0);
    } else {
      return costs.sort((a,b) => (a.costDate > b.costDate) ? 1 : (b.costDate > a.costDate) ? -1 : 0);
    }
  }
}
