
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { MyProfileService } from '../components';
@Injectable({
  providedIn: 'root'
})
export class AuthAdminGuard implements CanActivate {
  constructor(private myProfileService: MyProfileService, private auth: AuthorizeService, private router: Router){}
  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    let isAuth: boolean = false;
    console.log("isAuth " + isAuth)

    let req$ = this.auth.isAuthenticated();
    isAuth = await firstValueFrom(req$);
    console.log("isAuth " + isAuth)

    if (isAuth == true)
    {
      let adminres$ = this.myProfileService.isAdmin();
      let isAdmin = await firstValueFrom(adminres$);

      if(isAdmin) {
        console.log("Guard says is admin!!");
        return true;
      }
    }

    console.log("Guard says NOT admin!!");
    this.router.navigate(['/'], { queryParams: { returnUrl: state.url }});
    return false;
  }
}
