import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { GroupService } from './group.service';
import { Router } from '@angular/router';
import { TOASTR_TOKEN, Toastr } from '../services/toastr.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { map, tap } from 'rxjs/operators';

@Component({
  templateUrl: './group-create.component.html',
  styleUrls: ['./group-create.component.scss']
})
export class GroupCreateComponent implements OnInit {

  constructor(private authService: AuthorizeService, private groupService: GroupService, private router: Router, @Inject(TOASTR_TOKEN) private toastr: Toastr) {
  }

  newGroupForm: UntypedFormGroup
  name: UntypedFormControl
  description: UntypedFormControl
  nickname: UntypedFormControl

  ngOnInit() {
    this.name = new UntypedFormControl('', [Validators.required, Validators.maxLength(80)]),
    this.description = new UntypedFormControl('', [Validators.required, Validators.maxLength(400)]),
    this.nickname = new UntypedFormControl('', [Validators.required, Validators.maxLength(50)]),

    this.newGroupForm = new UntypedFormGroup({
      name: this.name,
      description: this.description,
      nickname: this.nickname
    })
  }

  add(formValues) {
    this.groupService.addGroup(formValues.name, formValues.description, formValues.nickname).subscribe(
      group => {
        console.log("created!", group);
        this.toastr.info("Grupp skapad!");
        this.router.navigate(['/group-start']);
      });//, this.authService.getUser().pipe(map(u => u && u.name)));
  }

  cancel() {
    this.router.navigate(['/group-start']);
  }
}
