import { Injectable, OnInit, Inject } from "@angular/core";
import { IGroup, IBalancingStartValues, IBalancing, IBalancingTransaction, IMember, IInvite } from "./group.model";
import { ICost } from "../cost/cost.model";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { AuthorizeService } from "src/api-authorization/authorize.service";
import { Observable, of, pipe } from 'rxjs'
import { catchError } from 'rxjs/operators';

@Injectable()
export class GroupService implements OnInit {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private authService: AuthorizeService
    ) { }

    ngOnInit() {
    }

    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          console.error(error);
          if (error.status === 401) {
            this.authService.signOut(null);
          }
          return of(result as T);
        }
    }

    getGroups(): Observable<IGroup[]> {
        console.log("getGroups(): ask for groups!");
        return this.http.get<IGroup[]>(this.baseUrl + 'api/Group/GetGroupForUser')
            .pipe(catchError(this.handleError<IGroup[]>('getGroups', [])))
    }

    getMembers(groupId: number): Observable<IMember[]> {
        return this.http.get<IMember[]>(this.baseUrl + 'api/Member/GetGroupMembers/' + groupId)
            .pipe(catchError(this.handleError<IMember[]>('getGroups', [])))
    }

    updateMember(groupId: number, memberId: number, newNickname: string) : Observable<IMember> {
        let data = {
            memberId: memberId,
            groupId: groupId,
            newNickname: newNickname
        }

        let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
        return this.http.patch<IMember>(this.baseUrl + 'api/Member/UpdateName', data, options)
            .pipe(catchError(this.handleError<IMember>('updateMember')));
    }

    getGroup(id: number): Observable<IGroup> {
        console.log("getGroup(): ask for group", id);
        return this.http.get<IGroup>(this.baseUrl + 'api/Group/GetGroup/' + id).pipe(catchError(this.handleError<IGroup>('getGroup')))
    }

    addGroup(name: string, description: string, nickname: string) : Observable<IGroup> {
        let data = {
            name: name,
            description: description,
            nickname: nickname
        }
        console.log("addGroup(): ask for groups!", data);

        let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
        return this.http.post<IGroup>(this.baseUrl + 'api/Group/AddGroup', data, options)
            .pipe(catchError(this.handleError<IGroup>('addGroup')));
    }

    addInvite(email: string, groupId: number) : Observable<any> {
      let data = {
          Email: email,
          GroupId: groupId
      }
      console.log("addInvite(): ask for !", data);

      let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
      return this.http.post<IGroup>(this.baseUrl + 'api/Group/AddInvite', data, options)
          .pipe(catchError(this.handleError<IGroup>('addInvite')));
    }

    getInvites(): Observable<IInvite[]> {
      console.log("getInvites():");
      return this.http.get<IInvite[]>(this.baseUrl + 'api/Group/GetInvites')
          .pipe(catchError(this.handleError<IInvite[]>('getInvites', [])))
    }

    createVirtualMember(groupId: number, memberName: string): Observable<IMember> {
        let data = {
            groupId: groupId,
            nickname: memberName
        }
        console.log("createVirtualMember(): with !", data);
        let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
        return this.http.put<IMember>(this.baseUrl + 'api/Member/CreateVirtualUser', data, options)
            .pipe(catchError(this.handleError<IMember>('createVirtualMember')));
    }

    accecptInvite(email: string, groupId: number) : Observable<boolean> {
      let data = {
          Email: email,
          GroupId: groupId
      }
      console.log("accecptInvite(): ask for !", data);

      let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
      return this.http.post<boolean>(this.baseUrl + 'api/Group/AccecptInvite', data, options)
          .pipe(catchError(this.handleError<boolean>('accecptInvite')));
    }

    declineInvite(email: string, groupId: number) : Observable<any> {
      let data = {
          Email: email,
          GroupId: groupId
      }
      console.log("aeclineInvite(): ask for !", data);

      let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
      return this.http.post<any>(this.baseUrl + 'api/Group/DeclineInvite', data, options)
          .pipe(catchError(this.handleError<any>('declineInvite')));
    }


    getBalancingStartValues(groupId: number) : Observable<IBalancingStartValues> {
        return this.http.get<IBalancingStartValues>(this.baseUrl + 'api/Balancing/GetStartValue/' + groupId.toString())
        .pipe(catchError(this.handleError<IBalancingStartValues>('getBalancingStartValues')))
    }

    balanceGroup(groupId: number) : Observable<IBalancing> {
        return this.http.get<IBalancing>(this.baseUrl + 'api/Balancing/GroupBalance/' + groupId.toString())
        .pipe(catchError(this.handleError<IBalancing>('balanceGroup')))
    }

    addPayment(payment: IBalancingTransaction, groupId: number) : Observable<boolean> {
        let data = {
            GroupId: groupId,
            UserTo: payment.userTo,
            UserFrom: payment.userFrom,
            Amount: payment.amount
        }
        console.log("addPayment()", data);

        let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
        return this.http.post<boolean>(this.baseUrl + 'api/Balancing/SavePayment', data, options)
            .pipe(catchError(this.handleError<boolean>('SavePayment')));
      }

      archiveGroup(groupId: number, status: boolean): Observable<boolean> {
        if (status) {
            return this.http.delete<boolean>(this.baseUrl + 'api/Group/Archive/' + groupId)
            .pipe(catchError(this.handleError<boolean>('archiveGroup')));
        } else {
            return this.http.delete<boolean>(this.baseUrl + 'api/Group/UnArchive/' + groupId)
            .pipe(catchError(this.handleError<boolean>('archiveGroup')));
        }
      }
}
