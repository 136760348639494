import { Component } from '@angular/core';
import { AuthorizeService } from "../../api-authorization/authorize.service"

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {

  public isLoggedIn: boolean;

  private auth: AuthorizeService;

  constructor(auth: AuthorizeService) {
    this.auth = auth;
  }

  ngOnInit() {
    
    this.auth.isAuthenticated().subscribe((data) => {
      this.isLoggedIn = data.valueOf();
    });
  }  

}
