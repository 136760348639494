import { Injectable, OnInit, Inject } from "@angular/core";
import { GroupService } from "../group/group.service";
import { ICost } from ".";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { AuthorizeService } from "src/api-authorization/authorize.service";
import { catchError } from 'rxjs/operators';

@Injectable()
export class CostService implements OnInit {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private authService: AuthorizeService) {
  }

  ngOnInit() {
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      if (error.status === 401) {
        //this.authService.signOut(null);
      }
      return of(result as T);
    }
}

  addCost(cost: ICost, groupId: number) : Observable<ICost> {
    let myDate = new Date(<any> cost.costDate);
    let data = {
      id: 0,
      title: cost.title,
      amount: cost.amount,
      date: myDate.toISOString(),
      memberHoldCost: cost.memberusername,
      membersToSplitCost: cost.splitOn,
      groupId: groupId,
    }  

    let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.post<ICost>(this.baseUrl + 'api/Cost/SaveCost', data, options)
        .pipe(catchError(this.handleError<ICost>('addCost')));
  }

  updateCost(updatedCost: ICost, groupId: number) {
    let data = {
      id: updatedCost.costId,
      title: updatedCost.title,
      amount: updatedCost.amount,
      date: updatedCost.costDate,
      memberHoldCost: updatedCost.memberusername,
      membersToSplitCost: updatedCost.splitOn,
      groupId: groupId,
    }  
    let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
    return this.http.patch<ICost>(this.baseUrl + 'api/Cost/UpdateCost', data, options)
        .pipe(catchError(this.handleError<ICost>('updateCost')));
  }

  deleteCost(costId: number) : Observable<boolean> {
    return this.http.delete<boolean>(this.baseUrl + 'api/Cost/Delete/' + costId)
        .pipe(catchError(this.handleError<boolean>('deleteCost')));
  }

  getCost(costId: number, groupId: number) : Observable<ICost> {
    return this.http.get<ICost>(this.baseUrl + 'api/Cost/Get/' + costId).pipe(catchError(this.handleError<ICost>('getCost')))
  }
}
