import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { CostService } from './cost.service';
import { ICost } from './cost.model';
import { ActivatedRoute, Router } from '@angular/router';
import { CostFormComponent } from './cost-form.component';
import { Toastr, TOASTR_TOKEN } from '../services';

@Component({
  templateUrl: './cost-viewedit.component.html'
})
export class CostViewEditComponent implements OnInit {

  constructor( 
              private costService: CostService,
              private route: ActivatedRoute,
              private router: Router,
              @Inject(TOASTR_TOKEN) private toastr: Toastr) { }

  costId: number;
  groupId: number;
  cost: ICost = <ICost> {}; ;
  isEdit: boolean = false;
  @ViewChild('costform') form: CostFormComponent;


  ngOnInit() {
    this.costId = +this.route.snapshot.queryParamMap.get('costId');
    this.groupId = +this.route.snapshot.queryParamMap.get('groupId');
    let edittext = this.route.snapshot.queryParamMap.get('edit');
    if (edittext === 'true') {
      console.log("edit mode!")
        this.isEdit = true;
    }
    this.costService.getCost(this.costId, this.groupId).subscribe(result => {
      this.cost = result;
      console.log("the cost: ", this.cost);

      if (this.cost === null) {
        this.router.navigate(['/group-start']);
      }
    });
  }

  cancel() {
    this.router.navigate(['group', this.groupId]);
}
  savedClicked(updatedCost: ICost) {
    this.cost.costId = this.costId;
    this.cost.amount = updatedCost.amount;
    this.cost.title = updatedCost.title;
    this.cost.memberusername = updatedCost.memberusername;
    this.cost.costDate = updatedCost.costDate;
    this.cost.splitOn = updatedCost.splitOn;
    this.costService.updateCost(this.cost, this.groupId).subscribe(result => {
      this.toastr.info("Kostnad uppdaterad!");
      this.router.navigate(['group', this.groupId]);
    });
  }

  deleteCost() {
    this.costService.deleteCost(this.costId).subscribe(result => {
      this.toastr.info("Kostnad borttagen!");
      this.router.navigate(['group', this.groupId]);
    } );
  }


  save() {
    this.form.costForm
  }

}
