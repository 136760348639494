import { Component, OnInit, Inject } from '@angular/core'
import { TOASTR_TOKEN, Toastr } from 'src/app/services/toastr.service'
import { AuthorizeService } from 'src/api-authorization/authorize.service'
import { MyProfileService } from './myprofile.service';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IUserExtraEmail } from '..';

@Component({
  templateUrl: './myprofile.component.html'
})
export class MyProfileComponent implements OnInit {
  constructor(
    private formBuilder: UntypedFormBuilder,
    private authService: AuthorizeService,
    private profileService: MyProfileService,
    @Inject(TOASTR_TOKEN) private toastr: Toastr)
  {}

  currentUsername: string;
  changePasswordForm: UntypedFormGroup;
  removeAccountPassword: string = "";
  mails: IUserExtraEmail[] = <IUserExtraEmail[]> new Array;
  hasEmailError: boolean = false;
  newExtraEmail: string = "";

  ngOnInit(): void {
    this.authService.getUser().subscribe(user => this.currentUsername = user.name);

    this.changePasswordForm = this.formBuilder.group({
      oldPassword: [null, Validators.required],
      newPassword: [null, Validators.required],
      repeatNewPassword: [null, [Validators.required, this.passwordMatch]]
    });

    this.getExtraEmailAddresses();
  }

  getExtraEmailAddresses(): void {
    this.profileService.getExtraEmails().subscribe(data => {
      this.mails = data;
    })
  }

  changePassword(changePasswordForm: UntypedFormGroup): void {
    if(changePasswordForm.valid){
      this.profileService.changePassword(changePasswordForm.value.oldPassword, changePasswordForm.value.newPassword).subscribe(result => {
        if (result) {
          this.toastr.info("Ditt lösenord är nu ändrat!");
          this.changePasswordForm.reset();
        } else {
          this.toastr.error("Det gick ej att ändra lösenord!");
        }
      });
    }
  }
  testEmail(): void {
    this.profileService.testActivateEmail();
  }

  validateEmail(email: string) : boolean {
    var  serchfind:boolean;
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    serchfind = regexp.test(email);

    if (email.length > 5) {
      if (serchfind == true) {
        this.hasEmailError = false;
      } else {
        this.hasEmailError = true;
      }
    }
    return serchfind
  }

  addEmail(): void {
    if (!this.validateEmail(this.newExtraEmail)) {
      this.toastr.error("Kan ej lägga till en ogiltig epost adress");
    }
    this.profileService.addExtraEmail(this.newExtraEmail).subscribe(result => {
      if (result) {
        this.toastr.info("Mailen tillagd!");
        this.getExtraEmailAddresses();
        this.newExtraEmail = "";
      } else {
        this.toastr.error("Misslyckades lägga till epost adressen");
      }
    })
  }

  removeEmail(email: string): void {
    this.profileService.removeExtraEmail(email).subscribe(result => {
      if (result) {
        this.toastr.info("Mailen borttagen!");
        this.getExtraEmailAddresses();
      } else {
        this.toastr.error("Misslyckades ta bort epost adressen");
      }
    })
  }

  removeAccount(removeAccountPassword: string): void {
    if (removeAccountPassword.length > 0) {
      this.profileService.removeAccount(removeAccountPassword).subscribe(result => {
        if (result) {
          this.toastr.info("Ditt konto är avslutat, tack och hej!");
          this.authService.signOut({ local: true });
        } else {
          this.toastr.error("Det gick ej att ta bort ditt konto, kontaka oss så hjälper vi dig!");
        }
      });
    }
  }

  private passwordMatch(control: AbstractControl){
    let paswd = control.root.get('newPassword');
    if(paswd && control.value != paswd.value){
     return {
         passwordMatch: false
     };
    }
    return true;
  }
}
