import { Component, OnInit, Input } from '@angular/core';
import { GroupService, IGroup } from 'src/app/group';

@Component({
  selector: 'group-list',
  templateUrl: './group-list.component.html',
  styleUrls: ['./group-list.component.scss']
})
export class GroupListComponent implements OnInit {

  groups: IGroup[];
  hasArchived: boolean;

  constructor(private groupService: GroupService) {
      this.groupService.getGroups().subscribe(value => {
        this.groups = value;
        console.dir(this.groups);
        if (this.groups.filter(p => p.archived == true).length > 0) {
          this.hasArchived = true;
        } else {
          this.hasArchived = false;
        };
      });
  }

  ngOnInit() {
  }

}