import { Injectable, OnInit, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthorizeService } from "src/api-authorization/authorize.service";
import { Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators';
import { IUserExtraEmail } from "./userextraemail.model";
import { lastValueFrom } from 'rxjs';

@Injectable()
export class MyProfileService implements OnInit {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string,
    private authService: AuthorizeService
    ) { }

    ngOnInit() {
    }

    private handleError<T> (operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
          console.error(error);
          if (error.status === 401) {
            this.authService.signOut(null);
          }
          return of(result as T);
        }
    }

    public isAdmin(): Observable<boolean> {
      //let loggedin = await this.authService.isAuthenticated().toPromise();
      //if (loggedin !== true) return false;
     // let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
      return this.http.get<boolean>(this.baseUrl + 'api/Profile/isuseradmin/');
    // let va = await lastValueFrom<boolean>(result$);
    //  return va;
    }

    testActivateEmail() {
      console.log("testActivateEmail");
      let url: string = this.baseUrl + 'api/Profile/ActivateEmail/id=kalleanka';
      console.log(url);
      return this.http.get<boolean>(this.baseUrl + 'api/Profile/ActivateEmail/id=kalleanka')
          .pipe(catchError(this.handleError('ActivateEmail')));
    }

    changePassword(oldPassword: string, newPassword: string) : Observable<boolean> {
        let data = {
            oldPassword: oldPassword,
            newPassword: newPassword
        }

        let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
        return this.http.patch<boolean>(this.baseUrl + 'api/Profile/ChangePassword', data, options)
            .pipe(catchError(this.handleError<boolean>('ChangePassword')));
    }

    removeAccount(password: string) : Observable<boolean> {
        let body = JSON.stringify(
            {
              password: password
            }
        );

        let options = { headers: new HttpHeaders({'Content-Type': 'application/json'}),
                        body: body
                        };

        return this.http.delete<boolean>(this.baseUrl + 'api/Profile/DeleteAccount', options)
        .pipe(catchError(this.handleError<boolean>('removeAccount')));
    }

    getExtraEmails() : Observable<IUserExtraEmail[]> {
      let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
      return this.http.get<IUserExtraEmail[]>(this.baseUrl + 'api/Profile/GetExtraEmail', options).pipe(catchError(this.handleError<IUserExtraEmail[]>('getExtraEmails')))
    }

    addExtraEmail(newMail: string) : Observable<boolean> {
      let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
      return this.http.put<boolean>(this.baseUrl + 'api/Profile/AddExtraEmail?newEmail=' + newMail, options).pipe(catchError(this.handleError<boolean>('addExtraEmail')))
    }

    removeExtraEmail(newMail: string) : Observable<boolean> {
      let options = { headers: new HttpHeaders({'Content-Type': 'application/json'})};
      return this.http.delete<boolean>(this.baseUrl + 'api/Profile/RemoveExtraEmail?extraEmail=' + newMail).pipe(catchError(this.handleError<boolean>('removeExtraEmail')))
    }
}
