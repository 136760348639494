import { Component, OnInit, Inject } from "@angular/core";
import { CostService } from "./cost.service";
import { GroupService, IGroup, IMember } from "../group";
import { ActivatedRoute, Router } from "@angular/router";
import { ICost } from ".";
import { Toastr, TOASTR_TOKEN } from "../services";

@Component({
    templateUrl: 'cost-add.component.html'
})
export class CostAddComponent implements OnInit {
    constructor(private costService: CostService,
        private groupService: GroupService,
        private route: ActivatedRoute,
        private router: Router,
        @Inject(TOASTR_TOKEN) private toastr: Toastr) {

    }

    group: IGroup;
    members: IMember[];
    cost: ICost = <ICost> {};
    groupId: number;

    ngOnInit() {
        this.groupId = +this.route.snapshot.paramMap.get('id');
        this.groupService.getGroup(this.groupId).subscribe(value => {
            this.group = value;
        });
    }

    cancel() {
        this.router.navigate(['group', this.group.groupId]);
    }

    savedClicked(cost: ICost) {
        let newcost: ICost = <ICost>{}
        newcost.amount = cost.amount;
        newcost.costDate = cost.costDate;
        newcost.memberusername = cost.memberusername;
        newcost.title = cost.title;
        newcost.splitOn = cost.splitOn;
        
        this.costService.addCost(newcost, this.group.groupId).subscribe(result => {
            this.toastr.info("Kostnad inlagd!");
            this.router.navigate(['group', this.group.groupId]);
        });
    }
}
