import { Component, OnInit, Inject } from '@angular/core';
import { GroupService } from './group.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TOASTR_TOKEN, Toastr } from '../services/toastr.service';
import { AuthorizeService, IUser } from 'src/api-authorization/authorize.service';
import { IGroup, IMember } from './group.model';

@Component({
  templateUrl: './group-admin.component.html'
})
export class GroupAdminComponent implements OnInit {
    
  constructor(private route:ActivatedRoute, private authService: AuthorizeService, private groupService: GroupService, private router: Router, @Inject(TOASTR_TOKEN) private toastr: Toastr) {
  }

  groupId: number;
  currentUsername: string;
  members: IMember[] = [];
  virtualMemberName: string = "";
  group: IGroup;

  ngOnInit() {
    this.authService.getUser().subscribe(user => this.currentUsername = user.name );
    this.groupId = +this.route.snapshot.paramMap.get('id');
    this.loadGroup();
    this.loadMembers();
  }

  loadGroup(): void {
    this.groupService.getGroup(this.groupId).subscribe(group => {
      this.group = group;
    })
  }

  loadMembers() {
    this.groupService.getMembers(this.groupId).subscribe(members => {
      console.log("membrs", members);
      this.members = members;
    })
  }

  updateName(member: IMember) {
    this.groupService.updateMember(this.groupId, member.memberId, member.groupNickname).subscribe(() => {
      this.loadMembers();
    });    
  }
  archive(status: boolean): void {
    this.groupService.archiveGroup(this.groupId, status).subscribe(() => {
      this.loadGroup();
    })
  }

  cancel(): void {
    this.router.navigate(['group', this.groupId]);
  }
  createVirutalMember(membername: string): void {
    this.groupService.createVirtualMember(this.groupId, this.virtualMemberName).subscribe(result => {
      if (result == null) {
        this.toastr.error("Kunde ej skapa virutella medlemen :(");
      } else {
        this.toastr.info("Virutell medlem skapad!")
        this.virtualMemberName = "";
        this.loadMembers();
      }
    });
  }
}


