import { Routes } from '@angular/router'

import { HomeComponent } from './home/home.component'
import { GroupStartComponent, GroupInvitedComponent, GroupCreateComponent, GroupComponent, GroupInviteComponent, GroupBalancingComponent, GroupAdminComponent } from './group'
import { AuthorizeGuard } from 'src/api-authorization/authorize.guard'
import { CostAddComponent } from './cost/cost-add.component'
import { AuthorizeService } from 'src/api-authorization/authorize.service'
import { CostViewEditComponent } from './cost'
import { MyProfileComponent } from './components'
import { AdminstartComponent } from './admin/adminstart/adminstart.component'
import { AuthAdminGuard } from './services'

export const appRoutes:Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full' },
    { path: 'group-start', component: GroupStartComponent, canActivate: [AuthorizeGuard] },
    { path: 'group-create', component: GroupCreateComponent, canActivate: [AuthorizeGuard] },
    { path: 'group-invited/:id', component: GroupInvitedComponent, canActivate: [AuthorizeGuard]},
    { path: 'group/:id', component: GroupComponent, canActivate: [AuthorizeGuard] },
    { path: 'group-invite/:id', component: GroupInviteComponent, canActivate: [AuthorizeGuard]},
    { path: 'group-balancing/:id', component: GroupBalancingComponent, canActivate: [AuthorizeGuard]},
    { path: 'group-admin/:id', component: GroupAdminComponent, canActivate: [AuthorizeGuard]},
    { path: 'cost-add/:id', component: CostAddComponent, canActivate: [AuthorizeGuard]},
    { path: 'cost-viewedit', component: CostViewEditComponent, canActivate: [AuthorizeGuard]},
    { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthorizeGuard]},
    { path: 'admin', component: AdminstartComponent, canActivate: [AuthorizeGuard, AuthAdminGuard]},
    { path: '**', redirectTo: '/'},
]
