import { Pipe, PipeTransform } from '@angular/core';
import { IGroup, IMember } from 'src/app/group';
/*
Sort IMembers after cost in group
 * Example:
  <div *ngFor="let member of group.members | memberCostPipe: group: true">
*/
@Pipe({name: 'memberCostPipe'})
export class MemberCostPipe implements PipeTransform {
  transform(members: IMember[], group: IGroup, ascending: boolean): IMember[] {

    if (!members) {
      return members;
    }
    if (ascending) {
      return members.sort((a,b) => (this.costByMember(b, group) > this.costByMember(a, group)) ? 1 : ((this.costByMember(a, group) > this.costByMember(b, group)) ? -1 : 0));
    } else {
      return members.sort((a,b) => (this.costByMember(a, group) > this.costByMember(b, group)) ? 1 : ((this.costByMember(b, group) > this.costByMember(a, group)) ? -1 : 0));
    }
  }

  costByMember(member: IMember, group: IGroup) : number {
    let value: number = 0;
    group.costs.forEach(cost => {
      if (cost.memberHoldCost.username === member.username) {
        value += cost.amount;
      }
    });
    return value;
  }
}
