import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-splitter-users',
  templateUrl: './splitter-users.component.html',
  styleUrls: ['./splitter-users.component.scss']
})
export class SplitterUsersComponent {
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") private baseUrl: string
  ) {}

  public users: any[];

  async ngOnInit() {
    this.users = await this.loadUsers();
  }

  async loadUsers(): Promise<any[]> {
    const result$ = this.http.get<any[]>(
      this.baseUrl + "api/admin/getusers/"
    );
    let va = await lastValueFrom<any[]>(result$);
    console.dir(va);
    return va;
  }

}
