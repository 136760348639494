import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { lastValueFrom } from "rxjs";

@Component({
  selector: "app-log-viewer",
  templateUrl: "./log-viewer.component.html",
  styleUrls: ["./log-viewer.component.scss"],
})
export class LogViewerComponent {
  constructor(
    private http: HttpClient,
    @Inject("BASE_URL") private baseUrl: string
  ) {}

  public logEvents: ILog[];

  async ngOnInit() {
    this.logEvents = await this.loadLogs();
  }

  public async EmptyLogTable() {
    await this.clearLogs();
    this.logEvents = await this.loadLogs();
  }

  async loadLogs(): Promise<ILog[]> {
    const result$ = this.http.get<ILog[]>(
      this.baseUrl + "api/admin/getlogfile/"
    );
    let va = await lastValueFrom<ILog[]>(result$);
    console.dir(va);
    return va;
  }

  async clearLogs(): Promise<boolean>{
    let result$ = this.http.get(this.baseUrl + "api/admin/ClearDbLogTable/");
    let va = await lastValueFrom(result$);
    return true;
  }
}



export interface ILog {
  id: number;
  message: string;
  messageTemplate: string;
  timeStamp: Date;
  logEvent: {};
  level: string;
  exception: string;
}
