import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupService } from './group.service';
import { IGroup } from './group.model';
import { Toastr, TOASTR_TOKEN } from '../services/toastr.service';

@Component({
  templateUrl: './group-invite.component.html'
})
export class GroupInviteComponent implements OnInit {

  constructor(
  private route:ActivatedRoute,
  private groupService: GroupService,
  private router: Router,
  @Inject(TOASTR_TOKEN) private toastr: Toastr) { }

  groupId: number;
  group: IGroup = <IGroup> {}; 
  friendEmail: string = "";
  hasError: boolean = false;

  ngOnInit() {
    this.groupId = +this.route.snapshot.paramMap.get('id');
    this.groupService.getGroup(this.groupId).subscribe(value => {
      this.group = value;
    });
  }

  validate() : boolean {
    var  serchfind:boolean;
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    serchfind = regexp.test(this.friendEmail);
    
    if (this.friendEmail.length > 5) {
      if (serchfind == true) {
        this.hasError = false;
      } else {
        this.hasError = true;
      }
    }
    
    return serchfind
  }

  invite() {
    this.groupService.addInvite(this.friendEmail, this.groupId).subscribe(result => {
      this.toastr.info("Kompisen är inbjuden!");
      this.router.navigate(['group', this.groupId]);
    })
  }
}