import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GroupService } from './group.service';
import { IGroup, IInvite } from './group.model';
import { Toastr, TOASTR_TOKEN } from '../services/toastr.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';

@Component({
  templateUrl: './group-invited.component.html'
})
export class GroupInvitedComponent implements OnInit {

  constructor(
  private route:ActivatedRoute,
  private groupService: GroupService,
  private router: Router,
  private authService: AuthorizeService,
  
  @Inject(TOASTR_TOKEN) private toastr: Toastr) { }

  inviteId: number;
  invite: IInvite;
  groupNickname: string = "";
  hasError: boolean = false;
  group: IGroup;
  userName: string;

  ngOnInit() {
    this.inviteId = +this.route.snapshot.paramMap.get('id');
    this.groupService.getInvites().subscribe(value => {
      value.forEach(element => {
        if (element.id == this.inviteId) {
          this.invite = element;
          this.groupService.getGroup(element.groupId).subscribe(result => {
            this.group = result;
          })
        }
      });

      if (this.invite == null) {
        this.router.navigate(['group-start']);
      }
    });
    this.authService.getUser().subscribe(user => this.userName = user.name);
  }

  validate(): boolean {
    if (this.groupNickname.length > 0) {
      this.hasError = false;
    } else {
      this.hasError = true;
    }

    const exist = this.group?.members?.find(u => u.groupNickname.toLowerCase() == this.groupNickname.toLowerCase());
    if (exist != null) {
      this.hasError = true;
    }

    return !this.hasError;
  }

  accept() {
    this.groupNickname = this.groupNickname.trim();
    if (this.validate() == false) {
      return;
    }

    this.groupService.accecptInvite(this.userName, this.invite.groupId).subscribe(result => {
      if (result == false) {
        this.router.navigate(['group-start']);
        console.log("failed to join group");
        return;
      }
      this.toastr.info("Välkommen till gruppen!");
      this.groupService.getGroup(this.invite.groupId).subscribe(group => {
        const member = group.members.find(m => m.username == this.userName);
        this.groupService.updateMember(this.invite.groupId, member.memberId, this.groupNickname).subscribe(() => {
          this.router.navigate(['group', this.invite.groupId]);
        });   
      })
    })
  }
}