import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ICost } from './cost.model';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { IMember, IGroup, GroupService } from '../group';
import { AuthorizeService } from '../../../src/api-authorization/authorize.service';
import { CostService } from './cost.service';

@Component({
  selector: 'cost-form',
  templateUrl: './cost-form.component.html'
})
export class CostFormComponent implements OnInit {

  constructor(
      private groupService: GroupService,
      private authService: AuthorizeService,
      private costService: CostService,
  ) {
    this.member = new UntypedFormControl('', [Validators.required, Validators.maxLength(200)]);
    this.title = new UntypedFormControl('', [Validators.required, Validators.maxLength(200)]);
    this.amount = new UntypedFormControl('', [Validators.required, Validators.pattern("^[-]?([1-9]{1}[0-9]{0,}(\.[0-9]{0,2})?|0(\.[0-9]{0,2})?|\.[0-9]{1,2})$"), Validators.min(0)]);
    this.date = new UntypedFormControl(Date.now(), [Validators.required]);

    this.costForm = new UntypedFormGroup({
        member: this.member,
        title: this.title,
        amount: this.amount,
        date: this.date,
    });
   }

    @Input() costId: number;
    @Input() groupId: number;
    @Input() isEdit: boolean = false;

    @Output() saveClicked = new EventEmitter<ICost>();
    @Output() cancelClicked = new EventEmitter();
    @Output() deleteClicked = new EventEmitter();

    costForm: UntypedFormGroup;
    member: UntypedFormControl
    title: UntypedFormControl
    amount: UntypedFormControl
    date: UntypedFormControl


    cost: ICost;
    splitOnMembersSource: IMember[];
    selectedUser: string;
    splitOnMembers: string[] = new Array();

    calendars: any;
    group: IGroup;
    members: IMember[];

    private updateform(cost: ICost) {
        console.log("the form to be edit:", this.cost);
        let pickerdate: Date = new Date(this.cost.costDate);
        this.member.setValue(this.cost.memberusername);

        this.selectedUser = this.cost.memberusername;
        this.title.setValue(this.cost.title);
        this.amount.setValue(this.cost.amount);

        this.setupCalender(pickerdate);

        this.date.setValue(pickerdate);

        if (cost.splitOn === undefined || cost.splitOn.length < 1) {
            this.splitOnMembers.push('all');
        } else {
            for (let index = 0; index < cost.splitOn.length; index++) {
                this.splitOnMembers.push(cost.splitOn[index]);
            }
        }
    }

    private setupCalender(startDate: Date) {
        let today = startDate.toDateString();
        let bulmaCalendar = window['bulmaCalendar'];
        let options = {
            weekStart: 1,
            displayMode: 'inline',
            lang: 'sv',
            showHeader: 'false',
            type: 'date',
            showButtons: 'false',
            showFooter: 'false',
            dateFormat: 'yyyy-MM-dd'
        };
        this.calendars = bulmaCalendar.attach('[type="date"]', options)[0];



        /*
        // Initialize all input of date type.
        this.calendars = bulmaCalendar.attach('[type="date"]', options)[0];
        console.dir(this.calendars);
        //this.calendars = bulmaCalendar.attach('#cost-date', options);
        // To access to bulmaCalendar instance of an element
        this.calendars.on('select', datepicker => {
            console.log("selected date!");
            let newDate: Date = new Date(datepicker.data.value());
            console.log("select", newDate);
            this.date.setValue(newDate);
        });
        */
        const element: any = document.querySelector('#cost-date');
        element.bulmaCalendar.date.start = startDate;
        element.bulmaCalendar.datePicker._visibleDate = startDate;
        element.bulmaCalendar.datePicker.refresh();
        console.dir(element);
        if (element) {


            element.bulmaCalendar.on('select', function(datepicker) {
                console.log(datepicker.data.value());
            });

            // bulmaCalendar instance is available as element.bulmaCalendar
            element.bulmaCalendar.on('select', datepicker => {
                console.log("selected date!");
                let newDate: Date = new Date(datepicker.data.value());

                this.date.setValue(newDate);
            });

            element.bulmaCalendar.refresh();
        }
    }

    ngOnInit() {
        this.groupService.getGroup(this.groupId).subscribe(value => {
            this.group = value;
            this.members = this.group.members;
            this.splitOnMembersSource = this.group.members;
            console.log("the group:", this.group);
        });

        if (this.isEdit === false) {
            this.splitOnMembers.push('all');
         //   this.cost = <ICost> { };
            this.authService.getUser().subscribe(user => this.selectedUser = user.name );
            this.setupCalender(new Date())
        } else {
            this.costService.getCost(this.costId, this.groupId).subscribe(result => {
                this.cost = result;
                console.log("the cost: ", this.cost);
                this.updateform(this.cost);
            });
        }
   }

    add(formValues) {
        console.log(formValues);
        let cost: ICost = <ICost>{}
        cost.amount = formValues.amount;
        cost.costDate = formValues.date;
        cost.memberusername = formValues.member;
        cost.title = formValues.title;

        cost.splitOn = new Array();
        for (let index = 0; index < this.splitOnMembers.length; index++) {
            cost.splitOn.push(this.splitOnMembers[index]);
        }
        console.log("the form created:" , cost);
        this.saveClicked.emit(cost);
    }

    cancel() {
        this.cancelClicked.emit();
    }

    delete() {
        this.deleteClicked.emit();
    }

   isChecked(value: string): boolean {
        if (this.splitOnMembers.length === 0) {
            this.splitOnMembers.push('all');
            return true;
        }

        if (this.splitOnMembers.find(m => m === value) === value) {
            return true;
        } else {
            return false;
        }
    }

    changeStatus(itemChanged: string) {
        if (this.splitOnMembers.find(m => m === itemChanged) === itemChanged) {
            this.splitOnMembers.forEach(function(item, index, object) {
                if (item === itemChanged) {
                object.splice(index, 1);
                }
            });
        } else {
            if (itemChanged !== 'all') {
                // remove all
                this.splitOnMembers.forEach(function(item, index, object) {
                    if (item === 'all') {
                    object.splice(index, 1);
                    }
                });
            }
            this.splitOnMembers.push(itemChanged);
        }

        if(this.isChecked('all')) {
            while (this.splitOnMembers.length) {
                this.splitOnMembers.pop();
            }
            this.splitOnMembers.push('all');
        }

        if (this.splitOnMembers.length === 0) {
            this.splitOnMembers.push('all');
        }
    }
}
