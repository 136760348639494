import { Component, Input, OnChanges } from '@angular/core';
import { IGroup, IMember, IPayment } from '../group';
import { ICost } from './cost.model';

@Component({
  selector: 'cost-timeline',
  templateUrl: './cost-timeline.component.html'
})
export class CostTimelineComponent implements OnChanges {

  constructor() {  }
  
  @Input() group: IGroup = null;
  costs: ICost[];
  payments: IPayment[];
  timeline: ITimeLineObject[] = [];

  topText = "Start";
  endText = "Nu";
  newestFirst = false;

  changeOrder() {
    this.newestFirst = !this.newestFirst;
    this.sortOrder();
    if (this.newestFirst) {
      this.topText = "Nu";
      this.endText = "Start";
    } else {
      this.topText = "Start";
      this.endText = "Nu";
    }
  }

  sortOrder(): void {
    if (this.newestFirst) {
      this.timeline = this.timeline.sort((a,b) => false ? new Date(a.date).getTime() - new Date(b.date).getTime() : new Date(b.date).getTime() - new Date(a.date).getTime());
    } else {
      this.timeline = this.timeline.sort((a,b) => false ? new Date(b.date).getTime() - new Date(a.date).getTime() : new Date(a.date).getTime() - new Date(b.date).getTime());

    }
  } 

  ngOnChanges() {
    if (this.group == undefined || this.group == null || this.group.costs == undefined || this.group.costs == null || this.group.payments == undefined || this.group.payments == null)  {
      return;
    }

    let timeArray: ITimeLineObject[] = [];
    console.log("start", this.group)
    this.group.costs.forEach(cost => {
      let item = <ITimeLineObject>{
        type: "cost",
        amount: cost.amount,
        groupId: this.group.groupId,
        id: cost.costId,
        date: cost.costDate,
        user: cost.memberHoldCost,
        title: cost.title
      }
      timeArray.push(item);
    });

    this.group.payments.forEach(payment => {
      let item = <ITimeLineObject>{
        type: "payment",
        amount: payment.amount,
        groupId: this.group.groupId,
        id: payment.paymentId,
        date: payment.paymentDate,
        user: payment.from,
        title: payment.title
      }
      timeArray.push(item);
    });

    this.timeline= timeArray.sort((a,b) => false ? new Date(b.date).getTime() - new Date(a.date).getTime() : new Date(a.date).getTime() - new Date(b.date).getTime());
  }
}

export interface ITimeLineObject {
  type: string;
  title: string;
  date: Date;
  amount: number;
  user: IMember;
  id: number;
  groupId: number;
}