import { Component, Inject, OnInit } from '@angular/core';
import { GroupService } from './group.service';
import { Toastr, TOASTR_TOKEN } from '../services/toastr.service';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { IInvite } from './group.model';


@Component({
  selector: 'group-invites',
  templateUrl: './group-has-invite.component.html'
})
export class GroupHasInviteComponent implements OnInit {

  constructor(
  private groupService: GroupService,
  private authService: AuthorizeService,
  
  @Inject(TOASTR_TOKEN) private toastr: Toastr) { }

  invites: IInvite[];
  userName: string;

  ngOnInit() {
    this.authService.getUser().subscribe(user => this.userName = user.name );
    this.loadInvites();
  }

  private loadInvites() {
    this.groupService.getInvites().subscribe(result => {
      this.invites = result;
    })
  }

  decline(groupId: number) {
    this.groupService.declineInvite(this.userName, groupId).subscribe(result => {
      this.toastr.info("Inbjudan avböjd");
      this.loadInvites();
    })
  }
}