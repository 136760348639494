import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'collapsible',
    templateUrl: './collapsible.component.html',
    styleUrls: ['./collapsible.component.scss']
})
export class CollapsibleComponent implements OnInit {

    constructor() {
    }

    status: boolean = false;

    toggle() : void {
        this.status = !this.status;       
    }

    ngOnInit() : void {
    }
}
