import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthorizeService } from 'src/api-authorization/authorize.service';
import { MyProfileService } from '../components/myprofile/myprofile.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  showNav: boolean;

  public isAuthenticated: Observable<boolean>;
  public isAdmin: Observable<boolean>;
  public userName: Observable<string>;

  constructor(private authorizeService: AuthorizeService, private myProfileService: MyProfileService){ }

  ngOnInit() {
    this.isAuthenticated = this.authorizeService.isAuthenticated();
    this.authorizeService.isAuthenticated().subscribe(o => {
      if (o === true) {
        this.isAdmin = this.myProfileService.isAdmin();
      }
    });
    this.userName = this.authorizeService.getUser().pipe(map(u => u && u.name));
  }
}
